import axios from 'axios'
import { Message } from 'element-ui';

import {
  getProjectLanguage
} from "../utils/store.js";

const service = axios.create({
  // timeout: 5000 // request timeout
})
export default service

service.interceptors.response.use(response => {


  // console.log(5521,response)

  // if (response.status == 200){
      return response
  // }else{
  //     // return Promise.reject(new Error())
  // }
},Error => {
  // console.log(55212,Error)
  // console.log(5521123,Error.response)


  Message({
    message: getProjectLanguage() =='english' ? "Network error" : "网络错误",
    type: "error",
    duration: 2000,
    showClose: true,
  });

  // return Promise.reject(Error)
})